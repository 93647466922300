import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <p><strong parentName="p">{`Usable with CoreMedia Content Cloud - Service and self-managed`}</strong></p>
    <hr></hr>
    <p><em parentName="p">{`CoreMedia Content Cloud`}</em>{` can benefit from an image transformation based on a shared service that supports
the WebP and Avif format out-of-the-box. Due to the nature of shared services, it works differently than the built-in
image transformation library used in earlier versions of the CoreMedia Content Cloud.`}</p>
    <p>{`Learn more about the specifics of and how to get access to the image transformation at
`}<a parentName="p" {...{
        "href": "https://documentation.coremedia.com/coremedia-services/image-transformation/image-transformation-cloud/"
      }}>{`Introduction To The Image Transformation`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      